/* login.css */

.login-container {
    /* display: flex; */
    height: 90vh;
  }
  
  .placeholder-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .login-form-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    background-color: #ffffff;
    margin: 0 10%; /* Add margins to center the form */
    max-width: 400px; /* Optional: Limit the maximum width */
  }
  
  .login-form-section h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .login-button:hover {
    background-color: #0052a3;
  }
  
  .login-links {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .login-links a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .login-links a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column;
      /* align-items: center; */
      justify-content: center; /* Center the form vertically */
    }
  
    /* .login-image-section {
      height: 30vh;
    } */

    .login-image-section {
        display: none; /* Hide the image section on mobile view */
    }
  
    .login-form-section {
      padding: 1rem;
      margin: 0 5%; /* Reduce margins for smaller screens */
    }
  }
  